<template>
  <div>
    <h1 class="page-title">Franchisee Dashboard</h1>

    <div class="mt-10">
      <v-col
        cols="3"
        v-for="category in dashboardCategories"
        :key="category.name"
        class="display-category m-4"
      >
        <div
          :style="`background-color: ${category.color}`"
          class="category-box"
        >
          <h1>{{ category.number }}</h1>
        </div>
        <p class="category-name">{{ category.name }}</p>
      </v-col>
    </div>
  </div>
</template>

<script>
import BaseService from "@/services/base.service.js";

export default {
  name: "boardAdmin",

  data() {
    return {
      dashboardCategories: [
        { name: "Active Franchises", color: "#facc4a", number: "20" },
        { name: "Active Franchisees", color: "#6fb5d6", number: "80" },
        { name: "Active Policies", color: "#63ce7a", number: "120" },
        { name: "Inactive Policies", color: "#8b8e8b", number: "120" },
        { name: "No. of Insured", color: "#d3fcd3", number: "20" },
        { name: "No. of un-insured", color: "#cef2ad", number: "40" },
        { name: "Insured Situations", color: "#b2851f", number: "60" },
        { name: "Uninsured Situations", color: "#635e7e", number: "30" },
      ],
      loading: false,
      items: [],
    };
  },

  mounted() {
    // this.getItems();
  },

  methods: {
    async getItems() {
      this.loading = true;
      let service = new BaseService("dashboard");

      try {
        let res = await service.get_all();

        this.items = res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.display-category {
  display: inline-block !important;
}
.category-box {
  height: 150px;
  font-size: 2rem;
  padding-top: 30px;
  text-align: center;
  border-radius: 1rem;
}
.category-name {
  text-align: center;
  margin: 4px;
}
</style>
